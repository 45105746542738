import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";

import { Link } from "react-router-dom";
import junoimg from "../img/networks/juno/junosmall.jpeg";
import comdeximg from "../img/networks/comdex/comdexsmall.jpeg";
import agoricimg from "../img/networks/agoric/agoricsmall.png";
import desmosimg from "../img/networks/desmos/desmossmall.jpeg";
import digimg from "../img/networks/dig/digsmall.jpeg";
import gravitybridgeimg from "../img/networks/gravitybridge/gravitysmall.png";

const FaqSection = () => {
  const [element, controls] = useScroll();
  return (
    <Faq
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        <span>Stake with Agaram.ai</span> and get rewards
      </h2>
      <AnimateSharedLayout>
        <Toggle title="How to buy and Stake?">
          <div className="answer">
            <p>
              Below is an example for buying and staking Juno. You can follow
              the same instructions for other tokens in Osmosis.
            </p>
            <p>
              Please delegate to <span>Agaram.ai</span>in the delegator list.
            </p>
            <iframe
              width="1190"
              height="670"
              src="https://www.youtube.com/embed/B2rGPLwuK6Y"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </Toggle>

        <Toggle title="Networks">
          <StyledNetworkList>
            <StyledNetwork>
              <Link
                to={{ pathname: "https://www.junonetwork.io/" }}
                target="_blank"
              >
                <motion.img src={junoimg} />
              </Link>
            </StyledNetwork>

            <StyledNetwork>
              <Link
                to={{ pathname: "https://comdex.one/home" }}
                target="_blank"
              >
                <motion.img src={comdeximg} />
              </Link>
            </StyledNetwork>

            <StyledNetwork>
              <Link to={{ pathname: "https://agoric.com/" }} target="_blank">
                <motion.img src={agoricimg} />
              </Link>
            </StyledNetwork>
            <StyledNetwork>
              <Link
                to={{ pathname: "https://www.desmos.network/" }}
                target="_blank"
              >
                <motion.img src={desmosimg} />
              </Link>
            </StyledNetwork>
            <StyledNetwork>
              <Link to={{ pathname: "https://digchain.org/" }} target="_blank">
                <motion.img src={digimg} />
              </Link>
            </StyledNetwork>
            <StyledNetwork>
              <Link
                to={{
                  pathname:
                    "https://blog.cosmos.network/the-gravity-bridge-chain-is-coming-to-cosmos-7010ec7bd0ea",
                }}
                target="_blank"
              >
                <motion.img src={gravitybridgeimg} />
              </Link>
            </StyledNetwork>
          </StyledNetworkList>

          {/* <div className="answer">
            <p>Lorem ipsum dolor sit amet.</p>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Necessitatibus, neque.
            </p>
          </div> */}
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled(About)`
  display: block;
  span {
    display: block;
  }

  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

// const Networks = styled.div`
//   display: flex;
//   flex-wrap: wrap;

//   @media (max-width: 1300px) {
//     justify-content: center;
//   }
// `;

// const StyledNetwork = styled.div`
//   min-height: 30vh;
//   box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
//   text-align: center;
//   border-radius: 1rem;
//   cursor: pointer;
//   overflow: hidden;
//   img {
//     width: 100%;
//     height: 40vh;
//     object-fit: cover;
//   }
// `;

const StyledNetwork = styled(motion.div)`
  padding: 5rem 5rem;
  h2 {
    padding: 5rem 5rem;
  }
`;

const StyledNetworkList = styled(motion.div)`
  min-height: 80vh;
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(400px, 5fr));
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
`;

export default FaqSection;
