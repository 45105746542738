import React from "react";
import home1 from "../img/home1.png";
import cosmosimg from "../img/cosmos.png";
import { About, Description, Image, Hide, CircleImage } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim, photoAnimTrans } from "../animation";
import Wave from "./Wave";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>Enterprise grade</span>
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>Staking</span>
            </motion.h2>
          </Hide>

          <Hide>
            <motion.h2 variants={titleAnim}>across Cosmos Ecosystem</motion.h2>
          </Hide>

          <Hide>
            <motion.h2 variants={titleAnim}>Building Apps to</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>Simplify Web3</span>
            </motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          We run Enterprise grade Validators to secure Cosmos Eco system
        </motion.p>
        {/* <Link to="/telegram">
          <motion.button variants={fade}>Contact Us</motion.button>
        </Link> */}
        <motion.p variants={fade}>
          <Link to={{ pathname: "https://t.me/agaramai" }} target="_blank">
            <motion.button variants={fade}>Telegram</motion.button>
          </Link>
          <Link
            to={{ pathname: "https://discord.gg/jBsaBHDWuK" }}
            target="_blank"
          >
            <motion.button variants={fade}>Discord</motion.button>
          </Link>
        </motion.p>
      </Description>
      <CircleImage>
        <motion.img variants={photoAnimTrans} src={cosmosimg} alt="Cosmos" />
      </CircleImage>
      <Wave />
    </About>
  );
};

//Styled Components

export default AboutSection;
