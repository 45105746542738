import React from "react";
//Import Icons
import clock from "../img/clock.svg";
import security from "../img/security.svg";
import monitor from "../img/monitor.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/home2.png";
//Styles
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";

const ServicesSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>
          <span>Agaram.ai</span> Services
        </h2>
        <Cards>
          <Card>
            <div className="icon">
              <img alt="icon" src={clock} />
              <h3>Founding Teams</h3>
            </div>
            <p>
              We provide technical consulting and infrastructure support for
              building Web 3 Apps.
            </p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={security} />
              <h3>Security</h3>
            </div>
            <p>
              Secure Staking Infrastructure with bare-metal servers and cloud
              infrastructure.
            </p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={monitor} />
              <h3>24/7 Monitoring</h3>
            </div>
            <p> Achieve enterprise level SLA/Availability</p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={money} />
              <h3>Wealth Management</h3>
            </div>
            <p>Grow your wealth with us</p>
          </Card>
        </Cards>
      </Description>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
  }
  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1300px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  flex-basis: 20rem;

  .icon {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 1rem;
      background: white;
      color: black;
      padding: 1rem;
    }
  }
`;

export default ServicesSection;
