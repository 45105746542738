import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//Images
import athlete from "../img/athlete-small.png";
import theracer from "../img/theracer-small.png";
import goodtimes from "../img/goodtimes-small.png";

import junoimg from "../img/networks/juno/juno.jpg";
import comdeximg from "../img/networks/comdex/comdex.jpeg";
import agoricimg from "../img/networks/agoric/agoric.png";
import desmosimg from "../img/networks/desmos/desmos.jpeg";
import gravitybridgeimg from "../img/networks/gravitybridge/gravity.png";
import digimg from "../img/networks/dig/dig.jpeg";
//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();
  const [element4, controls4] = useScroll();
  const [element5, controls5] = useScroll();
  const [element6, controls6] = useScroll();
  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>

      <Movie>
        <motion.h2 variants={fade}>Juno</motion.h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to={{ pathname: "https://www.junonetwork.io/" }} target="_blank">
          <Hide>
            <motion.img variants={photoAnim} src={junoimg} alt="juno" />
          </Hide>
        </Link>
      </Movie>

      <Movie ref={element} variants={fade} animate={controls} initial="hidden">
        <h2>Comdex</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to={{ pathname: "https://comdex.one/home" }} target="_blank">
          <img src={comdeximg} alt="comdex" />
        </Link>
      </Movie>

      <Movie
        ref={element2}
        variants={fade}
        animate={controls2}
        initial="hidden"
      >
        <h2>Agoric</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to={{ pathname: "https://agoric.com/" }} target="_blank">
          <img src={agoricimg} alt="Agoric" />
        </Link>
      </Movie>

      <Movie
        ref={element3}
        variants={fade}
        animate={controls3}
        initial="hidden"
      >
        <h2>Desmos</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to={{ pathname: "https://www.desmos.network/" }} target="_blank">
          <img src={desmosimg} alt="Desmos" />
        </Link>
      </Movie>

      <Movie
        ref={element4}
        variants={fade}
        animate={controls4}
        initial="hidden"
      >
        <h2>Gravity Bridge</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link
          to={{
            pathname:
              "https://blog.cosmos.network/the-gravity-bridge-chain-is-coming-to-cosmos-7010ec7bd0ea",
          }}
          target="_blank"
        >
          <img src={gravitybridgeimg} alt="Gravity Bridge" />
        </Link>
      </Movie>

      <Movie
        ref={element5}
        variants={fade}
        animate={controls5}
        initial="hidden"
      >
        <h2>Agoric</h2>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to={{ pathname: "https://digchain.org/" }} target="_blank">
          <img src={digimg} alt="Dig" />
        </Link>
      </Movie>
      <ScrollTop />
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }

  h2 {
    padding: 1rem 0rem;
  }
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background: #23d997;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #fffebf;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #ff8efb;
`;
const Frame3 = styled(Frame1)`
  background: #8ed2ff;
`;
const Frame4 = styled(Frame1)`
  background: #8effa0;
`;

export default OurWork;
